<template>
  <div class="title py-4">
    <SupplierInternalPartProduction
      :part="part"
      :supplier-u-u-i-d="$route.params.supplierUUID"
      :warehouse-part-u-u-i-d="$route.params.partUUID"
    ></SupplierInternalPartProduction>
  </div>
</template>


<script>
import ApiErrorParser from '@cloudmanufacturingtechnologies/portal-components/src/components/apiErrorParser/ApiErrorParser';
import SupplierInternalPartProduction from '../../../components/supplierInternalPartProduction/SupplierInternalPartProduction';

const i18nData = require('./pageBcmPartProduction.i18n.json');

export default {
  name: 'PageBcmPartProduction',
  components: {
    SupplierInternalPartProduction,
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      part: null,
    };
  },
  created() {
    this.getSupplierPart();
  },
  methods: {
    /**
     * GET SUPPLIER PART
     */
    getSupplierPart() {
      this.$apiInstance
        .getSupplierPart(
          this.$route.params.supplierUUID,
          this.$route.params.partUUID
        )
        .then(
          (partData) => {
            this.part = partData;
          },
          /**
           * ERROR GET SUPPLIER PART
           */
          (error) => {
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
  },
};
</script>
